import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, FormGroup, FormControlLabel, FormControl, TextField, Checkbox } from "@mui/material";
import { SubtitleInline } from "components/shared/Typography";
import { Tab, Menu } from "semantic-ui-react";
import { non_custom_insurance_types, supplementalCOIDocumentation } from "constants/insurance_types";

export default function InitialCoverageCOISupplementalDocumentation({ coverageSupplementalDocumentation = [], ...props }) {
console.log('Initial coverage', coverageSupplementalDocumentation)
  return (
    <Box component="div" {...props}>
      <SubtitleInline sx={{ mb: 3 }}>Required Supplemental Documentation</SubtitleInline>
      <Grid sx={{ my: 3 }} container spacing={0}>
        <FormGroup sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
          {supplementalCOIDocumentation.map((COIDocumentationType, index) => {
                  const otherValue = coverageSupplementalDocumentation?.find((oneType) => !supplementalCOIDocumentation.includes(oneType));
                  const checked =
                    COIDocumentationType !== "Other"
                      ? coverageSupplementalDocumentation?.includes(COIDocumentationType)
                      : otherValue === "" || !!otherValue;
          
                  return (
                    <Box sx={{ display: "flex" }} key={"supplementalCOIDocumentation-" + COIDocumentationType}>
                      <FormControl disabled sx={{ flexDirection: "row", alignItems: "center" }} key={COIDocumentationType + "Checkbox" + index}>
                        <FormControlLabel
                          control={<Checkbox name={COIDocumentationType} checked={!!checked} />}
                          label={COIDocumentationType}
                        />
                      </FormControl>
                      {COIDocumentationType === "Other" && checked && (
                        <TextField
                          name="OTHER_VALUE"
                          defaultValue={otherValue || ""}
                          variant={"standard"}
                          disabled
                        />
                      )}
                    </Box>
                  );
                })}
        </FormGroup>
      </Grid>
    </Box>
  );
}
