import React from "react";
import {Box, IconButton, InputAdornment, Link, Stack, TextField, Tooltip, Typography, Divider} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {Popup} from "semantic-ui-react";

import {SubtitleInline} from "components/shared/Typography";
import FileUploadButton from "components/shared/FileUploadButton";
import {useParams} from "react-router-dom";
import FileUploadDragAndDrop from "components/shared/FileUploadDragAndDrop";

export default function CertificateFileSection({
  vendor,
  acord25FileName, // name of the acord 25 file
  certificateFiles, // list of files from S3
  handleFileUpload,
  handleFileDelete,
  fileSelected,
  setFileSelected,
  showVendorLink,
  disableUpload = false,
  saving = false,
  vendorLabel
}) {
  const {clientId, certificateId} = useParams()
  const isCreate = certificateId === 'create';
  const acord25File = certificateFiles?.find(file => (file.name === acord25FileName || file.isAcord25) && !file.isDeleted)
  const fileLineItemProps = {fileSelected, setFileSelected}
  return (
    <>
      <Stack display="flex" justifyContent="space-between" flexDirection="row">
        <SubtitleInline>
          ACORD 25 FILE
          {
            acord25File
            ? <FileLineItem file={acord25File} {...fileLineItemProps} />
            : <Typography variant={"subtitle1"} color={"text.secondary"} ml={2}>No Acord 25 file selected.</Typography>
          }
          </SubtitleInline>
        <FileUploadDragAndDrop
          flexGrow={1}
          buttonText= {acord25File ? "REPLACE" : "SELECT OR DROP FILE"}
          buttonProps={{
            startIcon: <AddIcon />,
            disabled: disableUpload || saving
          }}
          onChange={event => handleFileUpload(event, acord25FileName || true)}
          />

      </Stack>


      <Stack display="flex" sx={{mt: 2}} justifyContent="space-between" flexDirection="row">
        <SubtitleInline >
        SUPPLEMENTAL FILES
        {
          certificateFiles?.map((uploadedFile, index) => (
              !uploadedFile.isAcord25 &&
              <FileLineItem file={uploadedFile} {...fileLineItemProps} handleFileDelete={handleFileDelete} key={"supplement"+ index}/>
            )
          )
          }
        </SubtitleInline>
        <FileUploadDragAndDrop
          flexGrow={1}
          buttonText="SELECT OR DROP FILE"
          buttonProps={{
            startIcon: <AddIcon />,
            disabled: disableUpload || saving
          }}
          sx={{border: 'none'}}
          onChange={handleFileUpload}
        />
      </Stack>
      <Divider sx={{mt: 2, mb: 1}}/>

      {
        showVendorLink &&
        <SubtitleInline>{`${vendorLabel} UPLOAD LINK`.toUpperCase()}</SubtitleInline>
      }

      {
        showVendorLink && !isCreate &&
        <Box sx={{mt:2}}>
          <Popup
            position='bottom center'
            trigger={
              <TextField
                fullWidth
                disabled
                value={window.location.origin + `/vendor/upload/${clientId}/${vendor?.id}/${certificateId}`}
                variant="outlined"
                onClick={(event)=>window.navigator.clipboard.writeText(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={()=>window.open(window.origin + "#/vendor/upload", "_blank")}
                        edge="end"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            }
          >
            Click to Copy URL
          </Popup>
        </Box>
      }
      {
        showVendorLink && !!isCreate &&
        <Typography variant="subtitle2" sx={{mt: 2}}>
          Please click the Save button to create this certificate.
          <br />
          The vendor upload link will only be available after this certificate is created.
        </Typography>
      }
    </>
  )
}

const FileLineItem = ({file, handleFileDelete, fileSelected, setFileSelected}) => (
  <Stack direction="row" alignItems={"center"}>
    {
      handleFileDelete &&
      <IconButton
        onClick={()=>handleFileDelete(file.name)}
        sx={{mr: 1}}
      >
        {
          file.isDeleted
            ? <RestorePageOutlinedIcon />
            : <DeleteForeverIcon />
        }
      </IconButton>
    }

    <Link
      component={"button"}
      onClick={() => setFileSelected({ name: file?.name })}
      color={
        (fileSelected?.name === file.name && !file.isDeleted)
          ? "primary"
          : "inherit"
      }
      sx={{textDecoration: file.isDeleted ? "line-through" : "underline", ml: (handleFileDelete ? 0 : 2)}}
    >
      {file.name}
    </Link>
    {
      file.isNew &&
      <Box sx={{ml: 1}}>
        <Tooltip title={"Your files are not saved until you click Save"} >
          <Typography variant="caption"> - new</Typography>
        </Tooltip>
      </Box>
    }
  </Stack>
)
