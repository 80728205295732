import React, {useState, useMemo, useEffect} from "react";
import {Box, Divider, Paper, Stack, Button} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DownloadIcon from "@mui/icons-material/Download";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";

import { SubtitleInline } from "components/shared/Typography";
import FileUploadDragAndDrop from "components/shared/FileUploadDragAndDrop";

export default function CertificateFileDisplay({
  certificateFiles,
  supplementalCoiFiles={},
  fileSelected,
  handleFileUpload,
  enableDownload,
  onRemove,
  hasEditPrivileges,
  disableUpload = false,
}) {

  const [pageSelected, setPageSelected] = useState(1);
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    setPageSelected(1)
  }, [fileSelected])
  
  const emptyCertFile = !certificateFiles?.length && !Object.keys(supplementalCoiFiles).length
  const fileDisplayed = useMemo(() => {
    if (fileSelected?.isSupplementalCoiFile && fileSelected.insuranceType && fileSelected.documentType) {
      return supplementalCoiFiles[fileSelected.insuranceType][fileSelected.documentType];
    } else {

      return certificateFiles?.find((file) => {
        return file.name === fileSelected?.name
      })
    }
  }, [fileSelected, certificateFiles?.length, supplementalCoiFiles])

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages)
  }
  const onLoadError = (error) => console.log('Error while loading document! ' + error.message)

  return (
    <Paper elevation={1} sx={{height: "100% !important", pb: 2, backgroundColor: "lightgrey"}}>
      {
        !!emptyCertFile ?
          <Stack sx={{minWidth: "800px", minHeight: "1035px", pt: 4,}}>
            {
              disableUpload &&
              <SubtitleInline
                sx={{
                  alignSelf: "center",
                  color: "gray"
                }}
              >{"NO FILE SELECTED FOR PREVIEW"}</SubtitleInline>
            }
            {
              !disableUpload &&
              <FileUploadDragAndDrop
                buttonText="SELECT OR DRAG AND DROP YOUR ACORD 25 PDF"
                buttonProps={{
                  startIcon: <UploadIcon />,
                  component:"span",
                  size:"large",
                }}
                sx={{
                  padding: 10,
                }}
                onChange={event => handleFileUpload(event, true)}
              />
            }
          </Stack>
          :
          <Stack ml={2} mr={2}>
            <Stack direction="row" alignItems={"center"}>
              <SubtitleInline>Pages</SubtitleInline>
              {
                <>
                  <Button
                    variant="text"
                    disabled={pageSelected==1}
                    sx={{visibility: numPages > 1 ? "visible" : "hidden"}}
                    onClick={()=>setPageSelected(selected => selected - 1)}
                  >
                    <KeyboardArrowLeftIcon />
                  </Button>
                  <SubtitleInline>{pageSelected}/{numPages}</SubtitleInline>
                  <Button
                    variant="text"
                    disabled={pageSelected==numPages}
                    sx={{visibility: numPages > 1 ? "visible" : "hidden"}}
                    onClick={()=>setPageSelected(selected => selected + 1)}
                  >
                    <KeyboardArrowRightIcon />
                  </Button>
                </>
              }
              <SubtitleInline sx={{margin:"auto"}}>{fileSelected?.name}</SubtitleInline>
              {
                !!enableDownload &&
                <Button
                  disabled={!hasEditPrivileges}
                  startIcon={<DownloadIcon />}
                  href={fileDisplayed?.content}
                  download={fileSelected?.name || "certificate.pdf"}
                  sx={{visibility: fileDisplayed?.isNew ? "hidden" : "visible"}}
                >
                  Download
                </Button>
              }
              {
                !!onRemove &&
                <Button
                  onClick={onRemove}
                >
                  Remove
                </Button>
              }
            </Stack>
            <Divider />
            {
              (certificateFiles?.length || Object.keys(supplementalCoiFiles).length) && fileSelected &&
              <Box sx={{width: "800px", alignSelf: "center"}}>
                <Document
                  className="certificate-file"
                  file={fileDisplayed?.content}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={onLoadError}
                >
                  <Page pageNumber={pageSelected} />
                </Document>
              </Box>
            }
          </Stack>
      }
    </Paper>
  );
}
