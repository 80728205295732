import React, { useEffect, useState, useMemo } from "react";

import { Divider, Button, Grid, CardContent, Typography, IconButton, Collapse, Box, Stack, Avatar } from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Feed, Image, Popup, Label, List } from "semantic-ui-react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import VendorInfoUpdateModal from "./VendorInfoUpdateModal";
import DeleteConfirmationModal from "components/shared/Modal/DeleteConfirmationModal";
import EmailConfirmationModal from "./EmailConfirmationModal";
import Snackbar from "components/shared/Snackbar";
import { SubtitleInline } from "components/shared/Typography";
import CollapsibleCard, { CollapsibleCardHeader } from "components/shared/CollapsibleCard";
import { updateLogo, getLogo } from "services/appResources";

import { useSelector, useDispatch } from "react-redux";
import { vendorState, vendorCertificatesState, vendorActions } from "redux/vendorSlice";
import { loggedInUserState } from "redux/userSlice";

import "./vendor-details.scss";
import { client_mode } from "constants/client_properties";
import { organizationState } from "redux/organizationSlice";
import { clientActions } from "redux/clientSlice";
import { formatPhoneNumber } from "utils/formUtils";
import { useNavPath, getNavigationPath, NAVIGATED_FROM } from "constants/routes";
import { SYSTEM, AGENCY, getVendorLabel, getProjectLabel } from "constants/organization_types";
import StatusIndicatorSelect from "components/shared/StatusIndicatorSelect";
import CertificateTable from "components/CertificateTable";
import ProjectLineItem from "./ProjectLineItem";

import useVendorActivities from "hooks/useVendorActivities";
import certificate_actions from "constants/certificate_actions";
import AmplitudeService, { AMPLITUDE_EVENT } from "services/amplitude";

const vendorStatuses = {
  COMPLIANT: { label: "Compliant", optionLabel: "Compliant" },
  NOT_COMPLIANT: { label: "Not Compliant", optionLabel: "Not Compliant" },
  ARCHIVED: { label: "Archived", optionLabel: "Archive" },
};

const notificationStatuses = {
  DISABLED: { label: "Disabled", optionLabel: "Disabled" },
  ACTIVE: { label: "Active", optionLabel: "Active" },
};

const vendorActivityAction = {
  EDIT: "updated",
};

export default function VendorDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAdmin, loggedInUser } = useSelector(loggedInUserState);
  const { selectedClient, userOrgType } = useSelector(organizationState);
  const clientMode = selectedClient?.mode;
  const hasEditPrivileges = isAdmin; //useMemo(() => [SYSTEM, AGENCY].includes(userOrgType), [userOrgType] || isAdmin)

  const [editVendor, setEditVendor] = useState(false);
  const [deleteVendor, setDeleteVendor] = useState(false);
  const [emailConfirm, setEmailConfirm] = useState(false);
  const [viewActivities, setViewActivities] = useState(false);
  const [viewCategories, setViewCategories] = useState(true);
  const [logo, setLogo] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const [viewMoreCategories, setViewMoreCategories] = useState(false);
  const [viewProjects, setViewProjects] = useState(true);
  const [viewMoreProjects, setViewMoreProjects] = useState(false);
  const [viewVendorInformation, setViewVendorInformation] = useState(true);
  const [viewMoreVendorInformation, setViewMoreVendorInformation] = useState(false);
  const [message, setMessage] = useState();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState(["Custom"]);
  const [emailCertificateId, setEmailCertificateId] = useState();
  const [showActivityDetail, setShowActivityDetail] = useState({ 0: false });

  const { vendorId, referrer, referrerId } = useParams();
  const { vendors } = useSelector(vendorState);
  const clientVendor = vendors?.find((oneVendor) => oneVendor.vendorID === vendorId);
  const vendor = { ...(clientVendor?.vendor || {}) };
  const [vendorActivities, addVendorActivity] = useVendorActivities(clientVendor);

  const categories = useSelector((state) => state.client.categories[selectedClient?.clientID]);
  const projects = useSelector((state) => state.client.projects[selectedClient?.clientID]);
  const defaultProject = projects?.find((project) => project.isDefault);
  const vendorCategories = useMemo(() => {
    const vendorCategoriesObject = {};
    if (clientVendor?.categories?.items?.length && categories?.length) {
      clientVendor.categories.items.forEach((vendorCategory) => {
        const category = categories.find((oneCategory) => oneCategory.id === vendorCategory.categoryID);
        if (category) {
          const { id, name, requiredCoverages } = category;
          if (vendorCategory.primary) {
            vendorCategoriesObject.primary = { id, name, requiredCoverages };
          } else {
            vendorCategoriesObject.others = [...(vendorCategoriesObject.others || []), { id, name, requiredCoverages }];
          }
        }
      });
    }
    return vendorCategoriesObject;
  }, [clientVendor?.categories?.items, categories]);
  const vendorProjects = useMemo(() => {
    if (clientVendor?.projects?.items.length && projects?.length) {
      return clientVendor.projects.items.map((vendorProject) => ({
        ...(projects.find((project) => vendorProject.projectID === project.id) || {}),
      }));
    }
  }, [clientVendor?.projects?.items, projects]);

  useEffect(() => {
    if (selectedClient?.clientID) {
      if (clientMode === client_mode.category && !categories) {
        dispatch(clientActions.fetchCategories(selectedClient.clientID));
      } else if (clientMode === client_mode.project && !projects) {
        dispatch(clientActions.fetchProjects(selectedClient.clientID));
      }
    }
  }, [selectedClient?.clientID]);

  const [loadingCertificates, setLoadingCertificates] = useState(false);
  const { vendorCertificates, vendorCertificatesCount } = useSelector(vendorCertificatesState);
  const [certificateLoadTime, setCertificateLoadTime] = useState(Date.now());

  async function loadVendorCertificates() {
    setLoadingCertificates(true);
    await dispatch(
      vendorActions.getAllVendorClientCertificates({
        clientID: selectedClient.clientID,
        clientVendorID: clientVendor.id,
      })
    );
    setCertificateLoadTime(Date.now());
    setLoadingCertificates(false);
  }

  useEffect(() => {
    if (selectedClient?.clientID) {
      if (!clientVendor) {
        dispatch(vendorActions.fetchVendors(selectedClient.clientID));
      } else {
        loadVendorCertificates();
      }
    }

    if (vendor?.logo) {
      getLogo(vendor.logo, (response) => setLogo(response));
    }

    if (selectedClient?.logo) {
      getLogo(selectedClient.logo, (response) => setClientLogo(response));
    }
  }, [clientVendor?.id, selectedClient?.clientID]);

  const NAV_PATH = useNavPath(userOrgType);
  const vendorLabel = getVendorLabel(selectedClient);
  const projectLabel = getProjectLabel(selectedClient);
  let referrerTitle = `${vendorLabel}s`;

  if (referrer === NAVIGATED_FROM.PROJECT) {
    referrerTitle = projectLabel;
  }

  const hasDisabledNotifications = clientVendor?.notificationStatus === "DISABLED";

  const projectMap = useMemo(() => {
    const mapToReturn = {};
    if (clientMode === client_mode.project && projects?.length) {
      projects.forEach((project) => {
        mapToReturn[project.id] = project.name;
      });
    }
    return mapToReturn;
  }, [projects, clientMode]);

  const vendorFields = {
    basic: [
      [
        {
          label: "Email",
          field: "email",
          ...(hasEditPrivileges &&
            !hasDisabledNotifications && {
              action: {
                icon: (
                  <Popup position="bottom center" trigger={<EmailIcon />}>
                    {`Email ${vendorLabel}`}
                  </Popup>
                ),
                onClick: () => {
                  setEmailCertificateId(null);
                  setEmailTemplates(["Custom"]);
                  setEmailConfirm(true);
                },
              },
            }),
        },
        {
          label: "Additional Emails",
          field: "additionalEmails",
          ...(hasEditPrivileges &&
            !hasDisabledNotifications && {
              action: {
                icon: (
                  <Popup position="bottom center" trigger={<EmailIcon />}>
                    {`Email ${vendorLabel}`}
                  </Popup>
                ),
                onClick: () => {
                  setEmailCertificateId(null);
                  setEmailTemplates(["Custom"]);
                  setEmailConfirm(true);
                },
              },
            }),
        },
        {
          label: "Phone",
          field: "phone",
          formatter: formatPhoneNumber,
        },
        {
          label: "Entity Name",
          field: "entityName",
          isVisible: vendor?.useEntityName,
        },
      ],
    ],
    additional: [
      { label: "Address 1", field: "address1" },
      { label: "Address 2", field: "address2" },
      [
        { label: "City", field: "city" },
        { label: "State", field: "state" },
        { label: "Zip Code", field: "zip" },
      ],
    ],
    insuranceAgent: [
      [
        { label: "Insurance Agent Name", field: "name", isInsuranceAgentField: true },
        { label: "Insurance Agent Email", field: "email", isInsuranceAgentField: true },
        { label: "Insurance Agent Phone", field: "phone", isInsuranceAgentField: true },
        { label: "Insurance Agent Address 1", field: "address1", isInsuranceAgentField: true },
        { label: "Insurance Agent Address 2", field: "address2", isInsuranceAgentField: true },
        { label: "Insurance Agent City", field: "city", isInsuranceAgentField: true },
        { label: "Insurance Agent State", field: "state", isInsuranceAgentField: true },
        { label: "Insurance Agent Zip Code", field: "zip", isInsuranceAgentField: true },
      ],
    ],
  };

  const certificateColumns = ["selected", "id", "status", "insured", "types", "producer", "certificateDate", "lastModifiedDate", "policyExp", "scan"];

  if (hasEditPrivileges && !hasDisabledNotifications) {
    certificateColumns.push("actions");
  }

  const VendorField = ({ label, field, content, action, clientMode, selectedClientMode, formatter, isInsuranceAgentField, isVisible = true }) => {
    const shouldDisplay = isVisible && (!clientMode || !selectedClientMode || clientMode === selectedClientMode);
    const displayContent = (isInsuranceAgentField && vendor.insuranceAgent ? vendor.insuranceAgent[field] : vendor[field]) || content;
    const formattedContent = formatter ? formatter(displayContent) : displayContent?.toString();
    return (
      shouldDisplay && (
        <Grid item sx={{ flexGrow: 1 }}>
          <SubtitleInline>{label}</SubtitleInline>
          <Typography variant="body1">
            {formattedContent}
            {!formattedContent && !content && "-"}
            {action && (content || vendor[field]) && <IconButton onClick={action.onClick}>{action.icon}</IconButton>}
          </Typography>
        </Grid>
      )
    );
  };

  const getActivityHeader = (field, activity, index) => {
    return (
      <Feed.Summary style={{ display: "flex", alignItems: "baseline", width: "100%" }}>
        <Box>
          <span style={{ fontWeight: "600" }}>{activity.userFullName}</span>
          {field.message ? ` ${field.message}` : activity.action !== "CREATE" ? ` updated ${vendorLabel}'s information` : ` created ${vendorLabel}`}
          {!!field.details && (
            <div>
              <Button
                size="small"
                sx={{ ml: "auto", flexShrink: 0 }}
                onClick={() =>
                  setShowActivityDetail({
                    ...showActivityDetail,
                    [index]: !showActivityDetail[index],
                  })
                }
              >
                {`${!!showActivityDetail[index] ? "Hide" : "Show"} details`}
              </Button>
            </div>
          )}
        </Box>
      </Feed.Summary>
    );
  };

  async function getUploadLink(certificateId, projectID, mode, recipients, requestedRequirements) {
    if (certificateId === "create") {
      let initialCoverageRequirements;
      if (projectID) {
        initialCoverageRequirements = projects.find(project => project.id === projectID)?.requiredCoverages
      }
      if (initialCoverageRequirements) {
        initialCoverageRequirements = {
          projectID: projectID,
          clientID: selectedClient.clientID,
          coverages: initialCoverageRequirements,
          requestedSupplementalDocumentation: requestedRequirements,
          requestedAt: new Date(),
        };
        initialCoverageRequirements = JSON.stringify(initialCoverageRequirements);
      }
      const result = await dispatch(
        vendorActions.createRequestCertificate({
          clientVendorID: clientVendor.id,
          clientID: selectedClient.clientID,
          sentBy: loggedInUser.email,
          sentTo: recipients,
          ...(projectID ? { projectID } : {}),
          ...(initialCoverageRequirements && { initialCoverageRequirements }),

        })
      );
      return window.location.origin + `/vendor/upload/${selectedClient.id}/${vendor?.id}/${result.payload.certificateId}`;
    } else {
      return window.location.origin + `/vendor/upload/${selectedClient.id}/${vendor?.id}/${certificateId}${mode ? `/${mode}` : ""}`;
    }
  }

  function onCancelVendorInfo(setUpdatedLogo) {
    setEditVendor(false);
    setUpdatedLogo(undefined);
  }

  function onCancelDelete() {
    setDeleteVendor(false);
  }

  async function onDeleteVendor() {
    //disable deleting for non-admin users
    if (!isAdmin) {
      setMessage({
        severity: "error",
        text: `You don't have permission to delete ${vendorLabel.toLowerCase()}`,
      });
      setDeleteVendor(false);
      return;
    }
    setDeleting(true);

    //Delete all vendor certificates
    await Promise.all(
      vendorCertificates.map(async (certificate, index) => {
        await dispatch(vendorActions.deleteCertificate(certificate));
        return Promise.resolve();
      })
    );

    const result = await dispatch(vendorActions.deleteVendor(clientVendor));
    if (result?.payload.success) {
      setMessage({
        severity: "success",
        text: `Successfully deleted ${vendorLabel.toLowerCase()}`,
      });
      setTimeout(() => {
        navBack();
      }, 1000);
    } else {
      setMessage({
        severity: "error",
        text: `Unable to delete ${vendorLabel.toLowerCase()}`,
      });
    }
    setDeleting(false);
    setDeleteVendor(false);
  }
  async function onUpdateVendorInfo(vendorData, updatedLogo, setUpdatedLogo) {
    //disable editing for non-admin users
    if (!isAdmin) {
      setMessage({
        severity: "error",
        text: `You don't have permission to update ${vendorLabel.toLowerCase()}`,
      });
      setEditVendor(false);
      return;
    }
    setSaving(true);
    if (updatedLogo) {
      try {
        const fetched = await fetch(updatedLogo.content);
        const blobbed = await fetched.blob();
        await updateLogo(`${vendorData.id}.${updatedLogo.extension}`, blobbed, updatedLogo.type);
      } catch (err) {
        handleLogoUpdateFailed(err, setUpdatedLogo);
        return;
      }
    }
    const input = { ...vendorData };
    updatedLogo && (input.logo = `${vendorData.id}.${updatedLogo.extension}`);

    const result = await dispatch(
      vendorActions.updateVendor({
        ...input,
        clientVendorID: clientVendor.id,
        categories: clientVendor.categories,
        projects: clientVendor.projects,
        defaultProject: projects?.find((project) => project.isDefault),
      })
    );
    if (result?.payload.success) {
      const { categoryUpdateResult, projectsUpdateResult } = result.payload;
      addVendorActivity({
        vendor: vendorData,
        ...(clientMode === client_mode.category ? { categoryUpdateResult, categories } : {}),
        ...(clientMode === client_mode.project && editVendor === client_mode.project ? { projectsUpdateResult, projects } : {}),
      });
      setMessage({
        severity: "success",
        text: `Successfully updated ${vendorLabel.toLowerCase()}`,
      });
      setEditVendor(false);
    } else {
      setMessage({
        severity: "error",
        text: `Unable to update ${vendorLabel.toLowerCase()}`,
      });
    }
    setSaving(false);
  }

  function handleLogoUpdateFailed(err, setUpdatedLogo) {
    setMessage({
      severity: "error",
      text: "Failed to upload new logo",
    });
    setUpdatedLogo(undefined);
  }

  async function onUpdateVendorStatus(status) {
    setSaving(true);
    const result = await dispatch(vendorActions.updateVendorStatus({ id: clientVendor.id, status }));
    if (result?.payload.success) {
      setMessage({
        severity: "success",
        text: `Successfully updated ${vendorLabel.toLowerCase()} status`,
      });
      addVendorActivity({
        vendor: {
          ...clientVendor.vendor,
          status,
          notificationStatus: clientVendor?.notificationStatus,
        },
      });
      AmplitudeService.logEvent(AMPLITUDE_EVENT.SUBCONTRACTOR_STATUS_CHANGED.eventName,
        AMPLITUDE_EVENT.SUBCONTRACTOR_STATUS_CHANGED.eventProperties({
          userEmail: loggedInUser?.email,
          clientId: selectedClient?.id,
          clientName: selectedClient?.name,
          previous: clientVendor?.status,
          next: status,
          subcontractorId: vendorId,
          subcontractorName: vendor?.name
        })
      )
      if (status === vendorStatuses.ARCHIVED.label.toUpperCase()) {
        const result = await dispatch(vendorActions.updateVendorNotificationStatus({ id: clientVendor.id, notificationStatus: "ACTIVE" }));
      }
      setEditVendor(false);
    } else {
      setMessage({
        severity: "error",
        text: `Unable to update ${vendorLabel.toLowerCase()} status`,
      });
    }
    setSaving(false);
  }

  async function onUpdateNotificationsStatus(notificationStatus) {
    setSaving(true);
    const result = await dispatch(vendorActions.updateVendorNotificationStatus({ id: clientVendor.id, notificationStatus }));
    if (result?.payload.success) {
      setMessage({
        severity: "success",
        text: `Successfully updated ${vendorLabel.toLowerCase()} notification status`,
      });
      addVendorActivity({
        vendor: {
          ...clientVendor.vendor,
          status: clientVendor.status,
          notificationStatus,
        },
      });
      setEditVendor(false);
    } else {
      setMessage({
        severity: "error",
        text: `Unable to update ${vendorLabel.toLowerCase()} notification status`,
      });
    }
    setSaving(false);
  }

  function navBack() {
    let navToPath;
    if (referrer === NAVIGATED_FROM.VENDORS) {
      navToPath = getNavigationPath(NAV_PATH.CLIENT_VENDORS, {
        clientId: selectedClient.id,
      });
      navigate(navToPath);
    } else if (referrer === NAVIGATED_FROM.PROJECT) {
      navToPath = getNavigationPath(NAV_PATH.CLIENT_PROJECT_DETAILS, {
        clientId: selectedClient.id,
        projectId: referrerId,
      });
      navigate(navToPath);
    } else {
      const vendorsPath = getNavigationPath(NAV_PATH.CLIENT_VENDORS, { clientId: selectedClient.id });
      navigate(vendorsPath);
    }
  }

  function navToCertificate(id) {
    const certificatePath = getNavigationPath(NAV_PATH.CLIENT_CERTIFICATE_DETAILS, {
      clientId: selectedClient.id,
      vendorId: vendorId,
      certificateId: id,
    });
    navigate(certificatePath, {
      state: {
        referrer: location.pathname,
      },
    });
  }

  function renderVendorFields (fields) {
    return fields.map((field, topIndex) =>
      Array.isArray(field) ? (
        <Grid item container spacing={2} direction="row" key={`vendor-field-row-${topIndex}`}>
          {field.map((subField, subIndex) => (
            <VendorField {...subField} selectedClientMode={clientMode} key={`vendor-field-${topIndex}_${subIndex}`} />
          ))}
        </Grid>
      ) : (
        <VendorField {...field} selectedClientMode={clientMode} key={`vendor-field-${topIndex}`} />
      )
    );
  }

  function onEmailSentResult(success) {
    if (success) {
      setMessage({
        text: "Successfully sent email notification.",
        severity: "success",
      });
    } else {
      setMessage({
        text: "Unable to send email notification.",
        severity: "error",
      });
    }
  }

  function onSendCertificateEmail(certificateId, emailTemplate) {
    setEmailCertificateId(certificateId);
    setEmailTemplates([emailTemplate]);
    setEmailConfirm(true);
  }

  return (
    <>
      <Button onClick={navBack}>
        <KeyboardArrowLeftIcon />
        Back to {referrerTitle}
      </Button>
      <Snackbar message={message} setMessage={setMessage} />
      <Snackbar message={hasDisabledNotifications && !message && { text: "Notifications are Inactive", severity: "error", duration: 3600000 }} />
      <VendorInfoUpdateModal
        open={editVendor}
        onClose={onCancelVendorInfo}
        onSave={onUpdateVendorInfo}
        clientMode={clientMode}
        vendor={vendor}
        loading={saving}
        categories={categories}
        projects={projects}
        vendorCategories={clientVendor?.categories}
        vendorProjects={clientVendor?.projects}
        logo={logo}
        vendorLabel={vendorLabel}
        projectLabel={projectLabel}
      />
      <DeleteConfirmationModal
        message={vendor?.name}
        content={`Please be aware that all certificates associated with this ${vendorLabel} will also be removed.`}
        open={deleteVendor}
        onClose={onCancelDelete}
        onDelete={onDeleteVendor}
        loading={deleting}
      />
      {/** vendor custom coverage requirement related, not used currently **/}
      {/*<CoverageUpdateModal*/}
      {/*  title={'Update Vendor Coverage Requirements'}*/}
      {/*  open={!!editCoverage}*/}
      {/*  loading={vendorCoverageLoading}*/}
      {/*  onClose={handleCoverageCancel}*/}
      {/*  coverages={selectedCoverages[editCoverage]}*/}
      {/*  clientCoverages={selectedClient?.clientCoverages?.items}*/}
      {/*  classCoverages={classCoverages}*/}
      {/*  classificationIDKey={classificationIDKey}*/}
      {/*  classificationID={editCoverage === "vendorDefault" ? null : editCoverage}*/}
      {/*  onSubmit={onSaveVendorCoverage}*/}
      {/*  clientMode={selectedClient?.mode}*/}
      {/*  showHelperText={true}*/}
      {/*/>*/}
      <EmailConfirmationModal
        open={!!emailConfirm}
        onClose={() => setEmailConfirm(false)}
        onEmailSentResult={onEmailSentResult}
        vendorName={vendor?.name}
        clientVendorID={clientVendor?.id}
        vendorEmail={vendor?.email}
        clientName={selectedClient?.name}
        clientId={selectedClient?.id}
        clientLogo={clientLogo}
        templateName={emailConfirm}
        clientTemplates={selectedClient?.templates}
        templates={emailTemplates}
        certificateId={emailCertificateId}
        getUploadLink={getUploadLink}
        vendorProjects={clientMode === client_mode.project ? vendorProjects : []}
        defaultProject={defaultProject}
        loggedInUser={loggedInUser}
        useClientDefaultProject={selectedClient?.useDefaultProject}
        additionalRecipients={[...(selectedClient?.usePrimaryNotificationEmailAddress ? [selectedClient?.primaryNotificationEmailAddress] : []), ...(Array.isArray(vendor?.additionalEmails)? vendor?.additionalEmails : [])]}
        projectLabel={projectLabel}
      />
      <Grid container spacing={6} pt={1}>
        <Grid item sm={12} md={4}>
          <Stack direction="row" mb={5} mt={2}>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {vendor?.name}
            </Typography>
            <StatusIndicatorSelect
              currentStatus={clientVendor?.status}
              statuses={vendorStatuses}
              onUpdateStatus={onUpdateVendorStatus}
              sx={{ ml: 2 }}
              size="large"
              disableSelection={!hasEditPrivileges}
            />
          </Stack>
          <CollapsibleCard>
            {
              <CollapsibleCardHeader
                action={hasEditPrivileges && <Button onClick={() => setEditVendor("info")}>Edit</Button>}
                show={viewVendorInformation}
                toggle={setViewVendorInformation}
                title={"Details"}
              />
            }
            <Divider />
            <Collapse in={viewVendorInformation}>
              <CardContent>
                {logo && (
                  <Grid container direction="row">
                    <VendorField content="Logo" />
                    <Image src={logo} size="small" style={{ marginBottom: "10px" }} />
                  </Grid>
                )}
                <Grid container spacing={2} direction="column">
                  {renderVendorFields(vendorFields.basic)}
                </Grid>
                <Collapse in={viewMoreVendorInformation}>
                  <Grid container spacing={2} direction="column" mt={1}>
                    {renderVendorFields(vendorFields.additional)}
                    {vendor.insuranceAgent && renderVendorFields(vendorFields.insuranceAgent)}
                  </Grid>
                </Collapse>

                <Button sx={{ padding: 0, mt: 2 }} onClick={() => setViewMoreVendorInformation(!viewMoreVendorInformation)}>
                  {`Show ${viewMoreVendorInformation ? "Less" : "More"}`}
                </Button>
              </CardContent>
            </Collapse>
          </CollapsibleCard>
          {clientMode === client_mode.category && (
            <CollapsibleCard>
              <CollapsibleCardHeader
                action={<Button onClick={() => setEditVendor(client_mode.category)}>{clientVendor?.categories?.items?.length ? "Update" : "Add"}</Button>}
                show={viewCategories}
                toggle={setViewCategories}
                title={
                  <>
                    <Box sx={{ mr: 1 }} component="span">
                      Categories
                    </Box>
                    <Label size={"tiny"}>{clientVendor?.categories?.items?.length || 0}</Label>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Collapse in={viewCategories}>
                  {!clientVendor?.categories?.items?.length && (
                    <Typography variant={"subtitle1"} color="text.secondary">
                      This vendor is not assigned to any categories.
                    </Typography>
                  )}
                  {vendorCategories?.primary && (
                    <Box sx={{ display: "flex" }}>
                      <Label>{vendorCategories.primary.name}</Label>
                      <Label basic color="blue" style={{ marginLeft: "auto" }}>
                        primary
                      </Label>
                    </Box>
                  )}
                  {!!vendorCategories?.others?.length && (
                    <>
                      <Collapse in={viewMoreCategories}>
                        {vendorCategories?.others?.map((otherCategory) => (
                          <Box sx={{ mt: 1 }}>
                            <Label>{otherCategory.name}</Label>
                          </Box>
                        ))}
                      </Collapse>
                      <Button sx={{ padding: 0, mt: 2 }} onClick={() => setViewMoreCategories(!viewMoreCategories)}>
                        {`Show ${viewMoreCategories ? "Less" : "More"}`}
                      </Button>
                    </>
                  )}
                </Collapse>
              </CardContent>
            </CollapsibleCard>
          )}
          {clientMode === client_mode.project && (
            <CollapsibleCard>
              <CollapsibleCardHeader
                action={hasEditPrivileges && <Button onClick={() => setEditVendor(client_mode.project)}>{vendorProjects?.length ? "Update" : "Add"}</Button>}
                show={viewProjects}
                toggle={setViewProjects}
                title={
                  <>
                    <Box sx={{ mr: 1 }} component="span">
                      {projectLabel}s
                    </Box>
                    <Label size={"tiny"}>{clientVendor?.projects?.items?.length || 0}</Label>
                    {
                      selectedClient?.useDefaultProject && 
                      <Typography variant="caption" color="red" ml={3} >
                        Client Default Coverages ENFORCED
                      </Typography>
                    }
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Collapse in={viewProjects}>
                  {!vendorProjects?.length && (
                    <Typography variant={"subtitle1"} color="text.secondary">
                      This {vendorLabel} is not assigned to any {projectLabel}s.
                    </Typography>
                  )}
                  {!!vendorProjects?.length && (
                    <Stack spacing={1}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <SubtitleInline secondary={true}>{`${projectLabel} NAME`.toUpperCase()}</SubtitleInline>
                        <SubtitleInline secondary={true} sx={{ ml: "auto", width: "141px" }}>
                          DURATION
                        </SubtitleInline>
                        <SubtitleInline secondary={true} sx={{ ml: 2, textAlign: "center" }}>
                          STATUS
                        </SubtitleInline>
                      </Box>
                      {vendorProjects.slice(0, 2).map((project, index) => (
                        <ProjectLineItem key={"project" + index} project={project} />
                      ))}
                    </Stack>
                  )}
                  {vendorProjects?.length > 2 && (
                    <Box mt={1}>
                      <Collapse in={viewMoreProjects}>
                        <Stack spacing={1}>
                          {vendorProjects.slice(2).map((project, index) => (
                            <ProjectLineItem key={"project" + (index + 2)} project={project} />
                          ))}
                        </Stack>
                      </Collapse>
                      <Button sx={{ padding: 0, mt: 2 }} onClick={() => setViewMoreProjects(!viewMoreProjects)}>
                        {`Show ${viewMoreProjects ? "Less" : "More"}`}
                      </Button>
                    </Box>
                  )}
                </Collapse>
              </CardContent>
            </CollapsibleCard>
          )}

          {isAdmin && (
            <CollapsibleCard
              title={`${vendorLabel} Activities`}
              show={viewActivities}
              toggle={setViewActivities}
              cardContent={
                <>
                  <Feed>
                    {vendorActivities?.map((activity, index) => {
                      let field = JSON.parse(activity.field || "{}");

                      return (
                        <Feed.Event key={"activity-" + index}>
                          <Feed.Label>
                            <Avatar>
                              {String(activity.userFullName)
                                .split(" ")
                                .map((part) => part.charAt(0))
                                .join("")
                                .toUpperCase()}
                            </Avatar>
                          </Feed.Label>
                          <Feed.Content>
                            <Feed.Date>{new Date(activity.createdAt).toLocaleString("en-us")}</Feed.Date>
                            {field.message ? (
                              <>
                                {getActivityHeader(field, activity, index)}
                                <Collapse in={!!showActivityDetail[index]}>
                                  {!!Object.keys(field.details || {}).length && (
                                    <Feed.Extra text>
                                      <List bulleted={true}>
                                        {Object.entries(field.details)
                                          .filter(([fieldName, fieldValue]) => fieldValue)
                                          .map(([fieldName, fieldValue]) => (
                                            <List.Item key={`${activity.id}_${fieldName}`}>
                                              <span style={{ textTransform: "capitalize", fontWeight: "600" }}>{fieldName}: </span>
                                              {fieldName === "certificateLink" ? (
                                                <a href={fieldValue} target="_blank">
                                                  {fieldValue}
                                                </a>
                                              ) : (
                                                <div dangerouslySetInnerHTML={{ __html: fieldValue.toString().replace(/\n/g, `<br />`) }}></div>
                                              )}
                                            </List.Item>
                                          ))}
                                      </List>
                                    </Feed.Extra>
                                  )}
                                </Collapse>
                              </>
                            ) : (
                              <>
                                {getActivityHeader(field, activity, index)}
                                {!!Object.keys(field).length && (
                                  <Feed.Extra text>
                                    <List bulleted={true}>
                                      {Object.entries(field).map(([fieldName, updates]) => (
                                        <List.Item key={`${activity.id}_${fieldName}`}>
                                          <p style={{ textTransform: "capitalize", fontWeight: "600" }}>{fieldName}</p>
                                          <Box sx={{ display: "flex", alignItems: "center" }}>
                                            {updates.prev ? (
                                              <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>{updates.prev?.toString()}</p>
                                            ) : (
                                              <p style={{ color: "darkgray" }}>{"blank"}</p>
                                            )}
                                            <ArrowForwardIcon sx={{ fontSize: 16, ml: 1, mr: 1 }} />
                                            {updates.next ? (
                                              <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>{updates.next?.toString()}</p>
                                            ) : (
                                              <p style={{ color: "darkgray" }}>{"blank"}</p>
                                            )}
                                          </Box>
                                        </List.Item>
                                      ))}
                                    </List>
                                  </Feed.Extra>
                                )}
                              </>
                            )}
                          </Feed.Content>
                        </Feed.Event>
                      );
                    })}
                  </Feed>
                </>
              }
            />
          )}
          {hasEditPrivileges && (
            <>
              <Stack direction="row" alignItems="end" justifyContent="space-between" mb={5} mt={2}>
                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                  Notifications
                </Typography>
                <StatusIndicatorSelect
                  currentStatus={clientVendor?.notificationStatus || "ACTIVE"}
                  statuses={notificationStatuses}
                  onUpdateStatus={onUpdateNotificationsStatus}
                  sx={{ ml: 2 }}
                  size="large"
                />
              </Stack>
              <Divider />
            </>
          )}
          {isAdmin && (
            <Button variant="outlined" color="error" startIcon={<DeleteIcon />} style={{ margin: "2rem auto" }} onClick={() => setDeleteVendor(true)}>
              Delete {vendorLabel}
            </Button>
          )}
        </Grid>
        <Grid item sm={12} md={8}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 700, fontSize: "1.15em", mb: 2 }}>
            Certificates
            {hasEditPrivileges && (
              <>
                <Popup
                  position="bottom center"
                  trigger={
                    <IconButton sx={{ ml: 2 }} onClick={() => navToCertificate("create")}>
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  }
                >
                  Add Certificate
                </Popup>
                <Button
                  size={"small"}
                  variant={"outlined"}
                  sx={{
                    ml: 2,
                    fontSize: "0.75rem",
                    fontWeight: 600,
                  }}
                  onClick={() => onSendCertificateEmail("create", certificate_actions.REQUEST)}
                  disabled={hasDisabledNotifications}
                >
                  Request Certificate
                </Button>
              </>
            )}
          </Typography>
          <CertificateTable
            key={"vendorCerTable" + certificateLoadTime}
            loading={loadingCertificates}
            certificates={vendorCertificates || []}
            certificatesCount={vendorCertificatesCount}
            onView={navToCertificate}
            clientMode={clientMode}
            compact={true}
            columns={certificateColumns}
            projects={projectMap}
            onSendEmail={onSendCertificateEmail}
            defaultRows={5}
            hasMultipleSelectionPermissions={isAdmin}
          />
        </Grid>
      </Grid>
    </>
  );
}
