import React from "react";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Done from "@mui/icons-material/Done";

export default function SupplementalCOIDocumentationErrorIndicatorHeader({ coverageErrors, text }) {
  return (
    <>
      {coverageErrors > 0 ? (
        <Badge badgeContent={coverageErrors} color="error">
          <Typography sx={{ paddingRight: "1rem" }}>{text}</Typography>
        </Badge>
      ) : (
        <Badge badgeContent={<Done sx={{ fontSize: 10, color: "white", fill: "white", stroke: "white", strokeWidth: 2 }} />} color="success">
          <Typography sx={{ paddingRight: "1rem" }}>{text}</Typography>
        </Badge>
      )}
    </>
  );
}
