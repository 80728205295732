import React, { useState } from "react";
import { Grid, Box, Typography, CardContent, Collapse, Stack } from "@mui/material";
import { SubtitleInline } from "components/shared/Typography";
import CollapsibleCard, { CollapsibleCardHeader } from "components/shared/CollapsibleCard";
import { displayCoveragesProperties, formatContent, displayCoverageLimits, getCoverageFullName } from "utils/projectCoverageRequirementsUtils";
import { Link } from "react-router-dom";
import { getNavigationPath, useNavPath, NAVIGATED_FROM } from "constants/routes";
import { CLIENT_NAV_PATH } from "constants/routes";
import { useSelector, useDispatch } from "react-redux";
import { organizationState } from "redux/organizationSlice";
import { awsDateToUS } from "utils/dateUtils";
import InitialCoverageCOISupplementalDocumentation from "./InitialCoverageCOISupplementalDocumentation";

export default function InitiallyRequestedProjectCoverages({
  project,
  coverageIndex,
  logo,
  initialCoverageRequirements,
  acord25FileDate,
  isOpen,
  setIsOpen = () => {},
  ...props
}) {
  const [viewCoverages, setViewCoverages] = useState(isOpen);
  const coverages = JSON.parse(initialCoverageRequirements?.coverages || project?.requiredCoverages || "[]") || [];
  const selectedCoverage = coverages.find((coverage, index) => index === coverageIndex);
  const isCustomCoverage = coverageIndex > 4;
  const { selectedClient, userOrgType } = useSelector(organizationState);
  const hasCoverageRequestedSupplementalDocumentation = initialCoverageRequirements?.requestedSupplementalDocumentation && initialCoverageRequirements.requestedSupplementalDocumentation[selectedCoverage?.name || selectedCoverage?.insuranceType];

  const NAV_PATH = useNavPath(userOrgType);

  if (!coverages.length) {
    return (
      <Typography color="red" variant="h6" my={2}>
        NOTICE: Initially requested project coverage requirements were not created
      </Typography>
    );
  }

  return (
    <CollapsibleCard>
      <CollapsibleCardHeader
        show={viewCoverages}
        sx={{
          padding: 0,
          alignItems: "center",
          ".MuiCardHeader-content .MuiButton-text": {
            padding: 0,
          },
        }}
        toggle={() => {
          setViewCoverages(!viewCoverages);
          setIsOpen(!viewCoverages);
        }}
        title={
          <>
            <Box component="span">INITIAL COVERAGE REQUIREMENTS</Box>
          </>
        }
      />
      <CardContent sx={{ padding: 0 }}>
        <Collapse in={viewCoverages}>
          <Box component="div" {...props} width="100%">
            <Stack>
              <Link
                target="_blank"
                sx={{ marginTop: 1 }}
                to={getNavigationPath(NAV_PATH.CLIENT_PROJECT_DETAILS, {
                  clientId: selectedClient.id,
                  projectId: project?.id,
                })}
              >
                {project?.name?.toUpperCase()}
              </Link>
              {initialCoverageRequirements?.requestedAt && (
                <Typography>Certificate Requested On: {awsDateToUS(initialCoverageRequirements?.requestedAt?.split("T")[0])}</Typography>
              )}
              {acord25FileDate && (
                <Typography>Certificate Uploaded On: {awsDateToUS(acord25FileDate)}</Typography>
              )}
            </Stack>
            <Grid sx={{ my: 3 }} spacing={0} display="flex" flexWrap="wrap" direction="row" >
              <Grid  item xs justifyContent="stretch" minWidth="20%" >
                <Box border={0.4} borderColor="grey.500" height={"100%"}>
                  <SubtitleInline
                    sx={{
                      backgroundColor: "#f3f4f7",
                      px: "10px",
                    }}
                  >
                    {!isCustomCoverage && getCoverageFullName(selectedCoverage?.insuranceType, selectedCoverage?.name)}
                  </SubtitleInline>
                  <ul style={{ padding: "10px", margin: "auto 10px" }}>
                    {Object.entries(displayCoveragesProperties(selectedCoverage) || {}).map(([key, value]) => {
                      return (
                        <li key={`${selectedCoverage?.insuranceType}-${key}}`}>
                          <Typography fontSize={12} color="text.secondary">
                            {key}: <b>{formatContent(value)}</b>
                          </Typography>
                        </li>
                      );
                    })}
                    {!isCustomCoverage && displayCoverageLimits(selectedCoverage)}
                    {isCustomCoverage && selectedCoverage?.limits && (
                      <li style={{ padding: "10px 0" }}>
                        Limits:
                        <ul style={{ paddingLeft: "10px" }}>
                          {Object.entries(selectedCoverage?.limits || {}).map(([key, value]) => {
                            return (
                              <li key={`limit-${selectedCoverage?.insuranceType}-${key}}`}>
                                <Typography fontSize={12} color="text.secondary">
                                  {key}: <b>${formatContent(value)}</b>
                                </Typography>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    )}
                  </ul>
                </Box>
              </Grid>
              {
                hasCoverageRequestedSupplementalDocumentation &&(
                <Grid flexBasis="50%" mx={1}>
                 <InitialCoverageCOISupplementalDocumentation coverageSupplementalDocumentation={initialCoverageRequirements.requestedSupplementalDocumentation[selectedCoverage?.name || selectedCoverage?.insuranceType]}/>
              </Grid>
                )
              }
            </Grid>
          </Box>
        </Collapse>
      </CardContent>
    </CollapsibleCard>
  );
}
