import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, FormGroup, FormControlLabel, FormControl, TextField, Checkbox } from "@mui/material";
import { SubtitleInline } from "components/shared/Typography";
import { Tab, Menu } from "semantic-ui-react";
import { non_custom_insurance_types, supplementalCOIDocumentation } from "constants/insurance_types";

export default function RequireCOISupplementalDocumentation({ project, onUpdate = () => {}, ...props }) {
  const [selectedDocumentation, setSelectedDocumentation] = useState({});
  const coverages = JSON.parse(project?.requiredCoverages || "[]") || [];

  useEffect(() => {
    const initialSelectedDocs = {};
    coverages.forEach((coverage) => {
      initialSelectedDocs[coverage.name || coverage.insuranceType] = (coverage.supplementalCOIDocumentation || []).filter(insType => insType.length);
    });
    setSelectedDocumentation({ ...initialSelectedDocs });
    onUpdate({ ...initialSelectedDocs });
  }, [project]);

  const onSupplementalDocumentationUpdate = (insuranceType, event) => {
    const coverageSupplementalCOIDocs = selectedDocumentation[insuranceType] || [];
    const supplementalCOIDocumentationSet = new Set(coverageSupplementalCOIDocs);
    const otherValue = coverageSupplementalCOIDocs?.find((oneApplies) => !supplementalCOIDocumentation?.includes(oneApplies));
    if (event.target.name === "Other") {
      if (event.target.checked) {
        supplementalCOIDocumentationSet.add("");
      } else {
        supplementalCOIDocumentationSet.delete(otherValue);
      }
    } else if (event.target.name === "OTHER_VALUE") {
      supplementalCOIDocumentationSet.delete(otherValue);
      supplementalCOIDocumentationSet.add(event.target.value);
    } else {
      if (event.target.checked) {
        supplementalCOIDocumentationSet.add(event.target.name);
      } else {
        supplementalCOIDocumentationSet.delete(event.target.name);
      }
    }
    setSelectedDocumentation({
      ...selectedDocumentation,
      [insuranceType]: Array.from(supplementalCOIDocumentationSet),
    });
    onUpdate({
      ...selectedDocumentation,
      [insuranceType]: Array.from(supplementalCOIDocumentationSet),
    });
  };

  const SupplementalDocumentationTypeSection = ({ insuranceType, coverageRequirement }) => (
    <FormGroup sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
      {supplementalCOIDocumentation.map((COIDocumentationType, index) => {
        const otherValue = coverageRequirement?.find((oneType) => !supplementalCOIDocumentation.includes(oneType));
        const checked =
          COIDocumentationType !== "Other"
            ? coverageRequirement?.includes(COIDocumentationType)
            : otherValue === "" || !!otherValue;

        return (
          <Box sx={{ display: "flex" }} key={"supplementalCOIDocumentation-" + COIDocumentationType}>
            <FormControl sx={{ flexDirection: "row", alignItems: "center" }} key={COIDocumentationType + "Checkbox" + index}>
              <FormControlLabel
                control={<Checkbox name={COIDocumentationType} checked={!!checked} onChange={(e) => onSupplementalDocumentationUpdate(insuranceType, e)} />}
                label={COIDocumentationType}
              />
            </FormControl>
            {COIDocumentationType === "Other" && checked && (
              <TextField
                name="OTHER_VALUE"
                onBlur={(e) => onSupplementalDocumentationUpdate(insuranceType, e)}
                defaultValue={otherValue || ""}
                variant={"standard"}
              />
            )}
          </Box>
        );
      })}
    </FormGroup>
  );

  const getPanes = () => {
    let panes = Array(Object.keys(selectedDocumentation).length).fill({});
    Object.keys(selectedDocumentation).forEach((insuranceType, tabIndex) => {
      const index = non_custom_insurance_types.indexOf(insuranceType);
      const pane = {
        menuItem: (
          <Menu.Item key={tabIndex}>
            <Typography sx={{ paddingY: "0.1rem" }}>{String(insuranceType).toUpperCase()}</Typography>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane style={{ border: "none", display: "flex", flexDirection: "column" }} key={tabIndex}>
            <SupplementalDocumentationTypeSection
              coverageRequirement={selectedDocumentation[insuranceType]}
              insuranceType={insuranceType}
            />
          </Tab.Pane>
        ),
      };
      if (index === -1) {
        panes.push(pane);
      } else {
        panes[index] = pane;
      }
    });

    return panes;
  };

  return (
    <Box component="div" {...props}>
      <SubtitleInline sx={{ my: 3 }}>Require Supplemental Documentation</SubtitleInline>
      <Grid sx={{ my: 3 }} container spacing={0}>
        <Tab panes={getPanes()} menu={{ secondary: true, pointing: true }} />
      </Grid>
    </Box>
  );
}
